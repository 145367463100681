import { Chip, LinearProgress, MenuItem, TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo, useRef, useState } from "react";
import DateInputField from "../../../app/common/form/DateInputFIeld";
import { useStyles } from "../../../app/layout/style";
import Title from "../../../app/layout/Title";
import { useStore } from "../../../app/stores/store";
import ReportCashFlowTable from "./ReportCashFlowTable";
import Filter from "../../../app/common/filter/Filter";
import Dropdown2 from "../../../app/common/form/Dropdown2";
import moment from "moment";
import { CashFlowSearch } from "../../../app/models/report";
import MySwitch from "../../../app/common/form/MySwitch";
import ButtonPrint from "../../../app/common/print/ButtonPrint";
import ComponentToPrint from "../../../app/common/print/ComponentToPrint";
import ButtonExcel from "../../../app/common/export/ButtonExcel";

export default observer(function ReportCashFlow() {
  const classes = useStyles();
  const { userStore, cashFlowStore, customerStore, salesStore } = useStore();
  const { storeGranted } = userStore;
  const { loadDrivers, drivers } = customerStore;
  const {
    setPredicate,
    loadReportCashflow,
    cashflowRegistry,
    loading,
    filter,
    filterPaymentType,
    setFilterPaymentType,
  } = cashFlowStore;
  const { loadPaymentType, paymentTypes } = salesStore;
  const [paymentTypeId, setPaymentTypeId] = useState(filterPaymentType);
  const [search, setSearch] = useState(filter);
  const [open, setOpen] = useState(false);
  const store = useMemo(
    () =>
      filter.storeId === "All"
        ? "All Store"
        : storeGranted.find((x) => x.id === filter.storeId)?.name,
    [filter.storeId, storeGranted]
  );
  let componentRef = useRef(null);

  useEffect(() => {
    if (paymentTypes.length <= 1) loadPaymentType();
    loadReportCashflow();
    loadDrivers();
  }, [paymentTypes.length, loadPaymentType, loadReportCashflow, loadDrivers]);

  const handleSetSearch = () => {
    setSearch(filter);
  };

  const handleSearchClick = (values: CashFlowSearch) => {
    setPredicate(values);
    setFilterPaymentType(paymentTypeId);
  };

  const handleFilter = (
    value: string,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) => {
    setFieldValue("filterBy", value);
  };

  const setFilterHandleChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const id = e.target.value as string;
    setPaymentTypeId(id);
  };

  return (
    <>
      <Title menuName="Report Cashflow">
        <>
          <ButtonExcel
            fileName="Report Cashflow"
            sheetName="Report Cashflow"
            componentToExport={componentRef}
            disabled={loading || cashflowRegistry.size === 0}
          />
          <ButtonPrint
            componentToPrint={componentRef}
            disabled={loading || cashflowRegistry.size === 0}
          />
          <Filter open={open} setOpen={setOpen} setSearch={handleSetSearch}>
            <div className={classes.filter}>
              <Formik
                enableReinitialize
                initialValues={search}
                onSubmit={(values) => handleSearchClick(values)}
              >
                {({ handleSubmit, values, setFieldValue }) => (
                  <Form onSubmit={handleSubmit} autoComplete="off">
                    <Grid
                      container
                      direction="column"
                      alignItems="flex-end"
                      spacing={1}
                    >
                      <Grid item>
                        <Grid item>
                          <div className={classes.paper}>
                            <div className={classes.sort_item}>
                              <Chip
                                label="Payment Date"
                                clickable
                                color={
                                  values.filterBy === "paymentDate"
                                    ? "primary"
                                    : "default"
                                }
                                onClick={() =>
                                  handleFilter("paymentDate", setFieldValue)
                                }
                              />
                              <Chip
                                label="Input Date"
                                clickable
                                color={
                                  values.filterBy === "inputDate"
                                    ? "primary"
                                    : "default"
                                }
                                onClick={() =>
                                  handleFilter("inputDate", setFieldValue)
                                }
                              />
                            </div>
                          </div>
                        </Grid>
                        <Grid item>
                          <DateInputField
                            label="Start Date"
                            name="startDate"
                            inputVariant="outlined"
                            margin="dense"
                          />
                        </Grid>
                        <Grid item>
                          <DateInputField
                            label="End Date"
                            name="endDate"
                            inputVariant="outlined"
                            margin="dense"
                          />
                        </Grid>
                        <Grid item>
                          <Dropdown2
                            name="storeId"
                            options={storeGranted.map((x) => {
                              return { text: x.name, value: x.id };
                            })}
                            label="Store"
                            variant="outlined"
                            margin="dense"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            titleDefaultSelected="All Selected"
                            displayDefaultSelected
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            name="paymentTypeId"
                            label="Payment Type"
                            value={paymentTypeId}
                            select
                            onChange={setFilterHandleChange}
                            fullWidth
                            margin="dense"
                            color="primary"
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          >
                            <MenuItem value="All">All Selected</MenuItem>
                            {paymentTypes
                              .filter((x) => x.type !== "DEPOSIT")
                              .map((payment) => (
                                <MenuItem key={payment.id} value={payment.id}>
                                  {payment.type}
                                </MenuItem>
                              ))}
                          </TextField>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Dropdown2
                          name="driverId"
                          options={drivers.map((x) => {
                            return { text: x.name, value: x.id };
                          })}
                          label="Driver"
                          variant="outlined"
                          margin="dense"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          titleDefaultSelected="All Selected"
                          displayDefaultSelected
                        />
                      </Grid>
                      <Grid item>
                        <MySwitch name="onlySales" label="Sales only" />
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                        >
                          Search
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </div>
          </Filter>
        </>
      </Title>
      <>
        {store && (
          <div className={classes.paperOnlyBottom}>
            <Typography variant="subtitle1" align="center">
              {store}
            </Typography>
            <Typography variant="subtitle2" align="center">
              {moment(filter.startDate).format("DD-MMM-yyyy")} To{" "}
              {moment(filter.endDate).format("DD-MMM-yyyy")}
            </Typography>
          </div>
        )}
        {filterPaymentType === "All"
          ? paymentTypes
              .filter((x) => x.type !== "DEPOSIT")
              .map((paymentType) => (
                <Paper className={classes.form} key={paymentType.id}>
                  <Typography variant="subtitle1">
                    {paymentType.type}
                  </Typography>
                  <Divider />
                  {loading ? (
                    <LinearProgress />
                  ) : (
                    <ReportCashFlowTable
                      cashFlow={cashflowRegistry.get(paymentType)}
                    />
                  )}
                </Paper>
              ))
          : paymentTypes
              .filter((x) => x.id === filterPaymentType)
              .map((paymentType) => (
                <Paper className={classes.form} key={paymentType.id}>
                  <Typography variant="subtitle1">
                    {paymentType.type}
                  </Typography>
                  <Divider />
                  {loading ? (
                    <LinearProgress />
                  ) : (
                    <ReportCashFlowTable
                      cashFlow={cashflowRegistry.get(paymentType)}
                    />
                  )}
                </Paper>
              ))}
        <ComponentToPrint
          title="Report Cashflow"
          componentToPrint={componentRef}
        >
          <>
            {store && (
              <div className={classes.paperOnlyBottom}>
                <Typography variant="subtitle1" align="center">
                  {store}
                </Typography>
                <Typography variant="subtitle2" align="center">
                  {moment(filter.startDate).format("DD-MMM-yyyy")} To{" "}
                  {moment(filter.endDate).format("DD-MMM-yyyy")}
                </Typography>
              </div>
            )}
            {filterPaymentType === "All"
              ? paymentTypes
                  .filter((x) => x.type !== "DEPOSIT")
                  .map((paymentType) => (
                    <div key={paymentType.id}>
                      <Typography variant="subtitle1">
                        {paymentType.type}
                      </Typography>
                      <Divider />
                      <ReportCashFlowTable
                        cashFlow={cashflowRegistry.get(paymentType)}
                      />
                    </div>
                  ))
              : paymentTypes
                  .filter((x) => x.id === filterPaymentType)
                  .map((paymentType) => (
                    <div key={paymentType.id}>
                      <Typography variant="subtitle1">
                        {paymentType.type}
                      </Typography>
                      <Divider />
                      <ReportCashFlowTable
                        cashFlow={cashflowRegistry.get(paymentType)}
                      />
                    </div>
                  ))}
          </>
        </ComponentToPrint>
      </>
    </>
  );
});
