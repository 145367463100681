import {
  Dialog,
  DialogTitle,
  Grid,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import * as Yup from "yup";
import DateInputField from "../../app/common/form/DateInputFIeld";
import Dropdown from "../../app/common/form/Dropdown";
import { useStyles } from "../../app/layout/style";
import { useStore } from "../../app/stores/store";
import { PaymentType, SalesPaymentValues } from "../../app/models/sale";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  ids: string[];
}

export default observer(function UpdateMassPayment({
  open,
  setOpen,
  ids,
}: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { salesStore, userStore, snackbarStore } = useStore();
  const { openSnackbar } = snackbarStore;
  const { user, restrictedPaymentDate } = userStore;
  const {
    updatePayment,
    setSelectedItem,
    setSaleSelectedItem,
    paymentTypes,
    bankAccounts,
    loadBankAccount,
    loadPaymentType,
  } = salesStore;
  const [paymentType, setPaymentType] = React.useState<PaymentType | undefined>(
    undefined
  );
  const [paymentMassValues, setPaymentMassValues] =
    React.useState<SalesPaymentValues>(new SalesPaymentValues());

  useEffect(() => {
    if (paymentTypes.length <= 1) loadPaymentType();
    if (bankAccounts.length <= 1) loadBankAccount();
  }, [
    paymentTypes.length,
    loadPaymentType,
    bankAccounts.length,
    loadBankAccount,
  ]);

  const schema = Yup.object({
    date: Yup.date().required("Date is required."),
    paymentTypeId: Yup.string()
      .nullable()
      .required("Payment type is required."),
    bankAccountId: Yup.string().when("needAccountNo", {
      is: true,
      then: Yup.string().required("Bank account is required."),
      otherwise: Yup.string().notRequired(),
    }),
  });

  const handlePaymentTypeChange = (
    e: React.ChangeEvent<{ value: unknown }>,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) => {
    const type = paymentTypes.find((x) => x.id === e.target.value);
    setPaymentType(type);
    setFieldValue("bankAccountId", "");
    setFieldValue("date", user?.date);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const closeDialog = (
    event: {},
    reason: "backdropClick" | "escapeKeyDown"
  ) => {
    if (!reason) setOpen(false);
  };

  const handleFormSubmit = (
    values: SalesPaymentValues,
    resetForm: () => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    let payment = new SalesPaymentValues({
      ...values,
      bankAccountId:
        values.bankAccountId === "" ? undefined : values.bankAccountId,
    });

    updatePayment(ids, payment)
      .catch((error) => {
        setSelectedItem([]);
        setSaleSelectedItem([]);
        openSnackbar(error, "error");
        setSubmitting(false);
        setOpen(false);
      })
      .then((message) => {
        if (message !== undefined) {
          setSelectedItem([]);
          setSaleSelectedItem([]);
          openSnackbar(message, "success");
          setPaymentMassValues(new SalesPaymentValues());
          setOpen(false);
          setSubmitting(false);
          resetForm();
        }
      });
  };

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      maxWidth="md"
      fullWidth
      fullScreen={fullScreen}
    >
      <Formik
        validationSchema={schema}
        enableReinitialize
        initialValues={paymentMassValues}
        onSubmit={(values, { resetForm, setSubmitting }) =>
          handleFormSubmit(values, resetForm, setSubmitting)
        }
      >
        {({ handleSubmit, isSubmitting, isValid, setFieldValue }) => (
          <Form onSubmit={handleSubmit} autoComplete="off">
            <DialogTitle>POS Payment</DialogTitle>
            <DialogContent dividers>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                <Grid item xs>
                  <Dropdown
                    options={paymentTypes
                      .filter((y) => y.isMandatory)
                      .map((x) => {
                        return {
                          text: x.type,
                          value: x.id,
                        };
                      })}
                    name="paymentTypeId"
                    onChange={(e) => handlePaymentTypeChange(e, setFieldValue)}
                    placeholder="Payment Type"
                    label="Payment Type"
                    margin="dense"
                    disabled={isSubmitting}
                  />
                </Grid>
                <Grid item>
                  <DateInputField
                    label="Date"
                    name="date"
                    placeholder="Please input date"
                    disabled={
                      isSubmitting ||
                      (!paymentType?.needAccountNo &&
                        paymentType?.type !== "SIMOLEX")
                    }
                    inputVariant="standard"
                    minDate={restrictedPaymentDate()}
                    maxDate={user!.date}
                    margin="dense"
                  />
                </Grid>
                <Grid item md>
                  <Dropdown
                    options={bankAccounts.map((x) => {
                      return {
                        text: `${x.owner} - ${x.bankName} - ${x.accountNo}`,
                        value: x.id,
                      };
                    })}
                    name="bankAccountId"
                    placeholder="Bank Account"
                    label="Bank Account"
                    disabled={isSubmitting || !paymentType?.needAccountNo}
                    margin="dense"
                    disableDefaultSelected={paymentType?.needAccountNo}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => handleCancel()}
                color="primary"
                disabled={isSubmitting}
              >
                Cancel
              </Button>
              <Button color="primary" type="submit" disabled={isSubmitting}>
                {isSubmitting && (
                  <CircularProgress
                    className={classes.progress}
                    size={16}
                    color="inherit"
                  />
                )}
                Pay
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
});
