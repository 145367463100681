import {
  action,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from "mobx";
import agent from "../api/agent";
import { CashFlowSearch, CashFlow } from "../models/report";
import { PaymentType } from "../models/sale";
import { store } from "./store";
import { TableStore } from "./tableStore";

export default class CashFlowStore extends TableStore<CashFlow> {
  cashflowRegistry = new Map<PaymentType, CashFlow[]>();
  filterPaymentType: string = "All";

  constructor() {
    super();
    makeObservable(this, {
      cashflowRegistry: observable,
      filterPaymentType: observable,
      setFilterPaymentType: action,
      loadReportCashflow: action,
    });

    this.setPredicate(new CashFlowSearch());

    reaction(
      () => this.predicate.keys(),
      () => {
        this.loadReportCashflow();
      }
    );
  }

  setFilterPaymentType = (id: string) => {
    runInAction(() => {
      this.filterPaymentType = id;
    });
  };

  setPredicate = (value: CashFlowSearch) => {
    runInAction(() => {
      Object.keys(value).forEach((key) => {
        this.predicate.delete(key);
      });
      this.predicate.set("filterBy", value.filterBy);
      this.predicate.set(
        "storeId",
        value.storeId === "All" ? "" : value.storeId
      );
      this.predicate.set("startDate", value.startDate.toLocaleDateString("EN-US"));
      this.predicate.set("endDate", value.endDate.toLocaleDateString("EN-US"));
      this.predicate.set("onlySales", value.onlySales ? "true" : "false");
      this.predicate.set(
        "driverId",
        value.driverId === "All" ? "" : value.driverId
      );
    });
  };

  get filter() {
    return new CashFlowSearch({
      filterBy:
        this.predicate.get("filterBy") === undefined
          ? "paymentDate"
          : this.predicate.get("filterBy")!,
      storeId:
        this.predicate.get("storeId") === undefined ||
        this.predicate.get("storeId") === ""
          ? "All"
          : this.predicate.get("storeId")!,
      startDate:
        this.predicate.get("startDate") === undefined
          ? new Date()
          : new Date(this.predicate.get("startDate")!),
      endDate:
        this.predicate.get("endDate") === undefined
          ? new Date()
          : new Date(this.predicate.get("endDate")!),
      onlySales:
        this.predicate.get("onlySales") === undefined
          ? false
          : this.predicate.get("onlySales")! === "true",
      driverId:
        this.predicate.get("driverId") === undefined ||
        this.predicate.get("driverId") === ""
          ? "All"
          : this.predicate.get("driverId")!,
    });
  }

  loadReportCashflow = async () => {
    this.clearCashflow();
    this.loading = true;

    try {
      const result = await agent.Reports.reportCashFlow(this.axiosParams);
      runInAction(() => {
        store.salesStore.paymentTypes
          .filter((x) => x.type !== "DEPOSIT")
          .forEach((paymentType) => {
            let resultByPaymentType = result.filter(
              (x) => x.paymentType.id === paymentType.id
            );
            this.cashflowRegistry.set(paymentType, resultByPaymentType);
          });
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  private clearCashflow = () => {
    this.cashflowRegistry.clear();
  };
}
