import { Button, Hidden, FormControlLabel, Switch } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import DateInputField from "../../app/common/form/DateInputFIeld";
import { useStyles } from "../../app/layout/style";
import { InvoiceSearch } from "../../app/models/sale";
import { useStore } from "../../app/stores/store";
import InvoiceTable from "./InvoiceTable";
import * as Yup from "yup";
import Title from "../../app/layout/Title";
import Filter from "../../app/common/filter/Filter";
import SearchFilter from "../../app/common/form/SearchFilter";
import Dropdown2 from "../../app/common/form/Dropdown2";
import InvoiceCardList from "./InvoiceCardList";

export default observer(function InvoiceList() {
  const classes = useStyles();
  const { userStore, customerStore, salesStore } = useStore();
  const { storeGranted } = userStore;
  const { loadDrivers, drivers } = customerStore;
  const {
    setPredicate,
    defaultPrice,
    setDefaultPrice,
    filter,
    withTax,
    setWithTax,
  } = salesStore;
  const [search, setSearch] = useState(filter);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    loadDrivers();
  }, [loadDrivers]);

  const schema = Yup.object({
    startDate: Yup.date().required("Start date is required."),
    endDate: Yup.date().required("End date is required."),
  });

  const handleSetSearch = () => {
    setSearch(filter);
  };

  const handleSearchClick = (values: InvoiceSearch) => {
    setPredicate(values);
  };

  return (
    <>
      <Title menuName="Invoice">
        <>
          <FormControlLabel
            name="defaultPrice"
            control={
              <Switch checked={defaultPrice} onChange={setDefaultPrice} />
            }
            label="Show Default Price"
          />
          <FormControlLabel
            name="withTax"
            control={<Switch checked={withTax} onChange={setWithTax} />}
            label="Show Tax"
          />
          <Filter open={open} setOpen={setOpen} setSearch={handleSetSearch}>
            <div className={classes.filter}>
              <Formik
                validationSchema={schema}
                enableReinitialize
                initialValues={search}
                onSubmit={(values) => handleSearchClick(values)}
              >
                {({ handleSubmit, values }) => (
                  <Form onSubmit={handleSubmit} autoComplete="off">
                    <Grid
                      container
                      direction="column"
                      alignItems="flex-end"
                      spacing={1}
                    >
                      <Grid item>
                        <Dropdown2
                          name="storeId"
                          options={storeGranted.map((x) => {
                            return { text: x.name, value: x.id };
                          })}
                          label="Store"
                          variant="outlined"
                          margin="dense"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          titleDefaultSelected="All Selected"
                          displayDefaultSelected
                        />
                      </Grid>
                      <Grid item>
                        <Dropdown2
                          name="driverId"
                          options={drivers.map((x) => {
                            return { text: x.name, value: x.id };
                          })}
                          label="Driver"
                          variant="outlined"
                          margin="dense"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          titleDefaultSelected="All Selected"
                          displayDefaultSelected
                        />
                      </Grid>
                      <Grid item>
                        <Dropdown2
                          name="shift"
                          options={[
                            { text: "SHIFT 1", value: "SHIFT 1" },
                            {
                              text: "SHIFT 2",
                              value: "SHIFT 2",
                            },
                            {
                              text: "SHIFT 3",
                              value: "SHIFT 3",
                            },
                          ]}
                          label="Shift"
                          variant="outlined"
                          margin="dense"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          titleDefaultSelected="All Selected"
                          displayDefaultSelected
                        />
                      </Grid>
                      <Grid item>
                        <DateInputField
                          label="Start Date"
                          name="startDate"
                          maxDate={values.endDate}
                          inputVariant="outlined"
                          margin="dense"
                        />
                      </Grid>
                      <Grid item>
                        <DateInputField
                          label="End Date"
                          name="endDate"
                          minDate={values.startDate}
                          inputVariant="outlined"
                          margin="dense"
                        />
                      </Grid>
                      <Grid item>
                        <SearchFilter
                          name="customerName"
                          label="Customer Name"
                          variant="outlined"
                          margin="dense"
                        />
                      </Grid>
                      <Grid item>
                        <SearchFilter
                          name="documentNo"
                          label="Invoice No."
                          variant="outlined"
                          margin="dense"
                        />
                      </Grid>
                      <Grid item>
                        <Dropdown2
                          name="deliveryStatus"
                          options={[
                            { text: "Delivered", value: "true" },
                            { text: "Not Delivery Yet", value: "false" },
                          ]}
                          label="Delivery Status"
                          variant="outlined"
                          margin="dense"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          titleDefaultSelected="All Selected"
                          displayDefaultSelected
                        />
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                        >
                          Search
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </div>
          </Filter>
        </>
      </Title>
      <Hidden lgUp implementation="css">
        <InvoiceCardList />
      </Hidden>
      <Hidden mdDown implementation="css">
        <InvoiceTable />
      </Hidden>
    </>
  );
});
