import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../../app/common/table/EnhancedTableHead";
import { useStyles } from "../../../app/layout/style";
import { CustomerDeposit } from "../../../app/models/report";

interface Props {
  customerDeposit: CustomerDeposit[];
}

export default observer(function ReportCustomerDepositTable({
  customerDeposit,
}: Props) {
  const classes = useStyles();

  return (
    <>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table_small}
            size="medium"
            aria-label="customized table"
          >
            {customerDeposit !== undefined && customerDeposit?.length > 0 ? (
              <>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Customer Code</StyledTableCell>
                    <StyledTableCell>Customer Name</StyledTableCell>
                    <StyledTableCell>Store Name</StyledTableCell>
                    <StyledTableCell>Deposit</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {customerDeposit.map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell>{row.customerCode}</StyledTableCell>
                      <StyledTableCell>{row.customerName}</StyledTableCell>
                      <StyledTableCell>{row.storeName}</StyledTableCell>
                      <StyledTableCell>
                        {row.amount.toLocaleString()}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </>
            ) : (
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell colSpan={8}>No Data.</StyledTableCell>
                </StyledTableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
});
