import { Grid, Button } from "@material-ui/core";
import { Formik, Form } from "formik";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import Filter from "../../../app/common/filter/Filter";
import Dropdown2 from "../../../app/common/form/Dropdown2";
import SearchFilter from "../../../app/common/form/SearchFilter";
import { useStyles } from "../../../app/layout/style";
import Title from "../../../app/layout/Title";
import { CustomerDepositSearch } from "../../../app/models/report";
import { useStore } from "../../../app/stores/store";
import UserSkeleton from "../../Users/UserSkeleton";
import ReportCustomerDepositCard from "./ReportCustomerDepositCard";
import ReportCustomerDepositTable from "./ReportCustomerDepositTable";

export default observer(function ReportCustomerDeposit() {
  const classes = useStyles();
  const { userStore, customerDepositStore } = useStore();
  const { storeGranted } = userStore;
  const {
    setPredicate,
    loadReportCustomerDeposit,
    customerDepositRegistry,
    loading,
    filter,
    skeletonCardArray,
  } = customerDepositStore;
  const [search, setSearch] = useState(new CustomerDepositSearch());
  const [open, setOpen] = useState(false);

  useEffect(() => {
    loadReportCustomerDeposit();
  }, [loadReportCustomerDeposit]);

  const handleSetSearch = () => {
    setSearch(filter);
  };

  const handleSearchClick = (values: CustomerDepositSearch) => {
    setPredicate(values);
  };

  return (
    <>
      <Title menuName="Report Customer Deposit">
        <Filter open={open} setOpen={setOpen} setSearch={handleSetSearch}>
          <div className={classes.filter}>
            <Formik
              enableReinitialize
              initialValues={search}
              onSubmit={(values) => handleSearchClick(values)}
            >
              {({ handleSubmit }) => (
                <Form onSubmit={handleSubmit} autoComplete="off">
                  <Grid
                    container
                    direction="column"
                    alignItems="flex-end"
                    spacing={1}
                  >
                    <Grid item>
                      <Dropdown2
                        name="storeId"
                        options={storeGranted.map((x) => {
                          return { text: x.name, value: x.id };
                        })}
                        label="Store"
                        variant="outlined"
                        margin="dense"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        titleDefaultSelected="All Selected"
                        displayDefaultSelected
                      />
                    </Grid>
                    <Grid item>
                      <SearchFilter
                        name="customerName"
                        label="Customer Name"
                        variant="outlined"
                        margin="dense"
                      />
                    </Grid>
                    <Grid item>
                      <Button variant="contained" color="primary" type="submit">
                        Search
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </div>
        </Filter>
      </Title>
      <Grid container>
        <Grid item xs={12}>
          <div className={classes.paperOnlyBottom}>
            <Grid container spacing={1}>
              {loading
                ? skeletonCardArray().map((item, index) => (
                    <Grid key={index} item xl={3} lg={4} md={6} sm={6} xs={12}>
                      <UserSkeleton />
                    </Grid>
                  ))
                : customerDepositRegistry.length > 0 && (
                    <ReportCustomerDepositTable
                      customerDeposit={customerDepositRegistry}
                    />
                  )}
            </Grid>
          </div>
        </Grid>
      </Grid>
    </>
  );
});
